import { createTheme } from "@mui/material";

export const colors = {
    primary: '#73D3EA',
    black: '#000000',
    white: '#ffffff',
    midBlack: '#020202',
    lightWhite: 'rgba(255, 255, 255, 0.9)',
    lightBlack: 'rgba(0, 0, 0, 0.9)',
    tealBlue: '#1E6778',
    lightTealBlue: '#1e677800',
    gray: '#575757',
    smokeWhite: '#F5F5F5',
    antiFlashWhite: '#ECEEEE',
    pacificBlue: '#139ABF',
    middleBlue: '#73D3EA',
    gainsBoro: '#D9D9D9',
    davyGray: '#575757',
    erieBlack: '#1d1d1d',

}


export const muiTheme = createTheme();

// break points
// xs: 0,
// sm: 600,
// md: 900,
// lg: 1200,
// xl: 1536,

// mobile: 0,
// tablet: 640,
// laptop: 1024,
// desktop: 1200,

muiTheme.typography.h1 = {
    fontSize: '13rem',
    lineHeight: '170px',
    letterSpacing: '-0.01562em',
    fontFamily: `'Bebas Neue', cursive`,
    color: colors.white,
    textAlign: 'center',
    '@media (max-width:1024px)': {
        fontSize: '10rem', lineHeight: '130px'
    },
    '@media (max-width:768px)': {
        fontSize: '120px',
    },
    '@media (max-width:480px)': {
        // fontSize: '80px',
    },
    '@media (max-width:320px)': {
        fontSize: '70px', lineHeight: '75px'
    },
};
muiTheme.typography.h3 = {
    fontSize: '40px',
    letterSpacing: '-0.01562em',
    fontFamily: `'lucien', sans-serif`,
    fontWeight: 600,
    color: '#1368BD',
    textAlign: 'center',
    '@media (max-width:1024px)': {
        fontSize: '2rem',
    },
    '@media (max-width:768px)': {
        fontSize: '120px',
    },
    '@media (min-width:481px)': {
        fontSize: '45px', 
    },
    '@media (min-width:250px) and (max-width: 480px)': {
        fontSize: '35px', 
    },
};
muiTheme.typography.h4 = {
    textAlign: 'left',
    fontSize: '38px',
    fontFamily: `'Bebas Neue', cursive`,
    color: colors.black,
    fontWeight: '400',
    paddingBottom: '5px',
    '@media (max-width:1024px)': {
        fontSize: '35px',
    },
    '@media (max-width:768px)': {
        textAlign: 'center',
    },
    '@media (max-width:320px)': {
        fontSize: '30px',
    },
};
muiTheme.typography.h5 = {
    textAlign: 'center',
    fontSize: '43px',
    fontFamily: `'Bebas Neue', cursive`,
    color: colors.white,
    fontWeight: '400',
    '@media (max-width:320px)': {
        fontSize: '38px',
    },
};
muiTheme.typography.h6 = {
    textAlign: 'center',
    fontSize: '18px',
    fontFamily: `'Heebo', sanserif`,
    color: '#828282',
    lineHeight: '28px',
    fontWeight: '400',
    position: 'relative',
    top: '3px',
    '@media (max-width:1024px)': {
        fontSize: '40px',
    },
    '@media (max-width:768px)': {
        fontSize: '30px',
    },
    '@media (max-width:320px)': {
        fontSize: '20px', top: '-0.4px'
    },
};
muiTheme.typography.body1 = {
    fontSize: '18px',
    fontFamily: `'Poppins', sans-serif`,
    color: colors.midBlack,
    fontWeight: '400',
    textAlign: 'center',
    margin: '0 auto',
    '@media (max-width:1024px)': {
        fontSize: '16px',
    },
    '@media (max-width:768px)': {
        fontSize: '14px',
    },
    '@media (max-width:320px)': {
        fontSize: '12px',
    },
};
muiTheme.typography.body2 = {
    fontSize: '16px',
    fontFamily: `'Poppins', sans-serif`,
    color: colors.black,
    fontWeight: '400',
    textAlign: 'center',
    margin: '0 auto',
    '@media (max-width:320px)': {
        fontSize: '12px', lineHeight: '20px'
    },
};
muiTheme.typography.subtitle1 = {
    fontSize: '16px',
    fontFamily: `'Poppins', sans-serif`,
    color: colors.black,
    fontWeight: '400',
    textAlign: 'left',
    margin: '0 auto',
    paddingTop: '10px',
    lineHeight: '24px',
    '@media (max-width:1024px)': {
        fontSize: '15px',
    },
    '@media (max-width:768px)': {
        textAlign: 'center', fontSize: '14px',
    },
    '@media (max-width:320px)': {
        fontSize: '12px', lineHeight: '20px'
    },
};