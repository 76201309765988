import React from 'react';
import { Button } from 'react-bootstrap';

export const AppButtonFill = ({ title, width, onClick=()=>{} }) => {

    return (
        <Button className='AppButton AppButtonFill' onClick={onClick}>{title || ''}</Button>
    )
}

export const AppButtonOutline = ({ title, width, onClick=()=>{} }) => {

    return (
        <Button className='AppButton AppButtonOutline' onClick={onClick}>{title || ''}</Button>
    )
}

export const AppButton = ({ title, className, onClick=()=>{} }) => {

    return (
        <Button className={'AppScreensButton ' + className} onClick={onClick}>{title || ''}</Button>
    )
}