import React, { useEffect, useState } from 'react'
import { AppScreensHeader, AppScreensHeader2, AppTitleHeader } from '../../components/AppHeader';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import engFlag from '../../assets/images/eng_lang.jpeg';
import frFlag from '../../assets/images/fr_lang.jpeg';
import spFlag from '../../assets/images/sp_lang.jpeg';
import tkFlag from '../../assets/images/tk_lang.jpeg';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AppButton } from '../../components/AppButton';
import editIcon from '../../assets/images/editIcon.png';
import { Container, Row } from 'react-bootstrap';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useTranslation, withTranslation } from 'react-i18next';

const Profile = props => {

    const { t, i18n } = useTranslation();


    const nationalities = [
        { value: 'USD', },
        { value: 'EUR', },
        { value: 'BTC', },
        { value: 'JPY', },
    ];

    const revoveryNumbers = [
        { value: '2345 7686 4532 4531', },
        { value: '2345 7686 4532 4531', },
        { value: '2345 7686 4532 4531', },
        { value: '2345 7686 4532 4531', },
    ];

    const languageData = [
        { code: '+1', flag: engFlag },
        { code: '+2', flag: frFlag },
        { code: '+3', flag: spFlag },
        { code: '+4', flag: tkFlag }
    ]
    const [values, setValues] = useState({
        name: '',
        nameError: '',
        email: '',
        emailError: '',
        password: '',
        showPassword: false,
        passwordError: '',
        repeatPassword: '',
        showRepeatPassword: false,
        repeatPasswordError: '',
        nationality: '',
        nationalityError: '',
        revoveryNumbers: '',
        revoveryNumbersError: '',
        countryCode: '+1',
        countryFlag: engFlag,
        phoneNumber: '',
        phoneNumberError: '',
        currency: '',
        currencyError: '',
        amount: '',
        amountError: '',
    });
    const [isToggle, setIsToggle] = useState(false);
    const [tabValue, setTabValue] = useState(t('personalData'));
    const [birthdate, setBirthdate] = useState(null)


    const functions = {
        _handleTabChange: (event, newValue) => {
            setTabValue(newValue);
        },
        _handleChange: (prop) => (event) => {
            setValues({ ...values, emailError: '', passwordError: '', [prop]: event.target.value });
        },
        _toNavigate: () => {
            if (!values.name) {
                setValues({ ...values, nameError: 'Enter your name.', });
            }
            else if (!values.email) {
                setValues({ ...values, emailError: 'Enter your email address.', });
            }
            else if (!values.phoneNumber) {
                setValues({ ...values, phoneNumberError: 'Add phone number with country code.', });
            }
            // else { }
        },
        _onToggle: () => {
            setIsToggle(!isToggle)
        },
        _onSelectCode: (item) => {
            setValues({ ...values, countryCode: item.code, countryFlag: item.flag });
            setIsToggle(false)
        }
    }

    return (
        <div className='InnerScreens Profile'>
            <div className='bImage1' />

            <div className='bImage2' />

            <AppScreensHeader2 />

            <div className='contentContainer'>
                {/* <AppScreensHeader2 /> */}
                {/* <AppTitleHeader title={'Profile Details'} /> */}
                <div className='screenWrapper'>
                    <Container>
                        <Row>
                            <div className='ProfileWrapper emptyWrapper'>
                                <div className='whiteBgWrapper firstWrapper'>
                                    <p className='title'>{t('Profile Details')}</p>


                                    <Box sx={{ width: 'fit-content' }}>
                                        <Tabs
                                            value={t(tabValue)}
                                            onChange={functions._handleTabChange}
                                            textColor="secondary"
                                            indicatorColor=""
                                            aria-label="secondary tabs example"
                                            className='tabs'
                                        >
                                            <Tab className='tab' value={t("personalData")} label={t("Personal data")} />
                                            <Tab className='tab' value={t("settings")} label={t("Settings")} />
                                            <Tab className='tab' value={t("password")} label={t("Password")} />
                                        </Tabs>
                                    </Box>

                                    <Box
                                        component="form" sx={{ '& .MuiTextField-root': { mt: 1, mb: 1, width: '100%', maxWidth: '100%', }, }}
                                        validate autoComplete="off" className="profileForm" onSubmit={functions._toNavigate}
                                    >
                                        <div>
                                            <div className='inputSize'>

                                                <TextField
                                                    id="standard-required" variant='standard' label={t("Full Name")} className='inputStyles marginFields'
                                                    value={t(values.name)} onChange={functions._handleChange('name')}
                                                    error={values.nameError ? true : false} helperText={values.nameError ? t(values.nameError) : ''}
                                                />

                                                <TextField
                                                    id="standard-required" variant='standard' label={t("Email Address")} className='inputStyles marginFields'
                                                    value={t(values.email)} onChange={functions._handleChange('email')}
                                                    error={values.emailError ? true : false} helperText={values.emailError ? t(values.emailError) : ''}
                                                />
                                            </div>

                                            <div className='inputSize'>
                                                <div className='numberWrapper inputStyles'>
                                                    <div className='languageWrapper languageWrapperDiv' onClick={functions._onToggle}>
                                                        <img src={values.countryFlag} className='flag' />
                                                        <span className='language'>{values.countryCode}</span>
                                                    </div>
                                                    <div id="languageWrapper" className={isToggle ? "custom-select-wrapper open-dropdown" : "custom-select-wrapper"} onClick={functions._onToggle}>
                                                        <ul>
                                                            {languageData.map((item, index) => {
                                                                return (
                                                                    <li className='languageWrapper' key={String(index)} onClick={() => functions._onSelectCode(item)}>
                                                                        <img src={item.flag} className='flag' />
                                                                        <span className='language'>{item.code}</span>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>

                                                    <TextField
                                                        id="standard" variant='standard' label={t('Phone Number')}
                                                        value={values.phoneNumber} onChange={functions._handleChange('phoneNumber')}
                                                        error={values.phoneNumberError ? true : false} helperText={values.phoneNumberError ? t(values.phoneNumberError) : ''}
                                                        className='inputStyles'
                                                    />
                                                </div>

                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <div className='inputStyles datePickerWrapper'>
                                                        <DatePicker
                                                            label={t("Date Of Birth")} value={t(birthdate)}
                                                            onChange={(newValue) => { setBirthdate(newValue); }}
                                                            renderInput={(params) => <TextField id='standard' variant='standard' {...params} />}
                                                            className="datePicker"
                                                        />

                                                    </div>

                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                        <TextField
                                            id="standard-select-currency" variant='standard' select label={t("Nationality")} className='inputStyles nationalityWrapper'
                                            value={t(values.nationality)} onChange={functions._handleChange('nationality')}
                                            error={values.nationalityError ? true : false} helperText={values.nationalityError ? t(values.nationalityError) : ''}
                                        >
                                            {nationalities.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {t(option.value)}
                                                </MenuItem>
                                            ))}
                                        </TextField>


                                    </Box>
                                    <button className='ProfileButton'>{t("Update Profile")}</button>

                                </div>
                                <div className='secondWrapper'>
                                    <div className='whiteBgWrapper'>
                                        <p className='title'>{t('Receiving Wallet')}</p>

                                        <div className='fieldset'>

                                            <TextField
                                                id="standard-select-currency" variant='standard' select label={t("Recovery Numbers")} className='inputStyles RecoveryNumberSize'
                                                value={values.revoveryNumbers} onChange={functions._handleChange('revoveryNumbers')}
                                                error={values.revoveryNumbersError ? true : false} helperText={values.revoveryNumbersError ? t(values.revoveryNumbersError) : ''}
                                            >
                                                {revoveryNumbers.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {t(option.value)}
                                                    </MenuItem>
                                                ))}

                                            </TextField>
                                            <div>
                                                <span>{t("Edit")}</span>
                                            </div>
                                        </div>

                                        <button className='ProfileButton2' >{t("Resend Email")}</button>
                                    </div>
                                    <div className='whiteBgWrapper'>
                                        <p className='title'>{t('Two-Factor Varification')}</p>
                                        <p className='twoFactorPara'>{t('Two Factor Authentication, or 2FA, is an extra layer of protection used to ensure the security of online accounts beyond just a username and password.')}</p>
                                        <div className='flex currentStatusWrapper'>
                                            <button className='ProfileButton3' >{t("Enable 2FA")}</button>
                                            <div className='secondWrapperPFlex'>
                                                <p className='twoFactorPara'>{t('Current Status:')}</p> <p className='twoFactorPara currentStatus'>{t('Disabled')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>

            <AppScreensHeader />


        </div>
    )
}

export default withTranslation()(Profile)




