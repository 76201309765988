import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/AppResponsive.css";
import AppRoutes from './routes/AppRoutes';
import { useTranslation, initReactI18next, Trans } from "react-i18next";

const lngs = {
  en: { name: 'English', code: 'en', country_code: 'gb' },
  fr: { name: 'Français', code: 'fr', country_code: 'fr' },
  sp: { name: 'Español', code: 'sp', country_code: 'es' },
  tr: { name: 'Türk', code: 'tr', country_code: 'tr' },
};

function App() {
  return (
    <div className="App" style={{ display: "flex", flexDirection: 'column' }}>
      <AppRoutes />
    </div>
  );
}

export default App;
