import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { MenuItem, ThemeProvider, Typography } from '@mui/material';
import { muiTheme } from '../../config/theme';
import { colors } from './../../config/theme';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import { RiEyeLine, RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { AppButton } from '../../components/AppButton';
import { Link, useNavigate } from 'react-router-dom';
// import CopyrightIcon from '@mui/icons-material/Copyright';
import { Dropdown, DropdownButton, Form, InputGroup, Nav } from 'react-bootstrap';
import engFlag from '../../assets/images/eng_lang.jpeg';
import frFlag from '../../assets/images/fr_lang.jpeg';
import spFlag from '../../assets/images/sp_lang.jpeg';
import tkFlag from '../../assets/images/tk_lang.jpeg';
import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn} from 'react-icons/fa';
import {IoLogoYoutube} from 'react-icons/io';
import { SiMinutemailer } from 'react-icons/si';
import logo from '../../assets/images/logo.png';
import { withTranslation, useTranslation } from 'react-i18next';

const Register = props => {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    const nationalities = [
        { value: 'USD', },
        { value: 'EUR', },
        { value: 'BTC', },
        { value: 'JPY', },
    ];
    const languageData = [
        { code: '+1', flag: engFlag },
        { code: '+2', flag: frFlag },
        { code: '+3', flag: spFlag },
        { code: '+4', flag: tkFlag }
    ]
    const currencies = [
        { value: 'Ethereum (ETH)', },
        { value: 'Ethereum (ETH)', },
        { value: 'Ethereum (ETH)', },
        { value: 'Ethereum (ETH)', },
    ];

    const socialLinks = [
        { tag: <FaFacebookF />, href: '#' },
        { tag: <FaInstagram />, href: '#' },
        { tag: <IoLogoYoutube />, href: '#' },
        { tag: <FaTwitter />, href: 'https://twitter.com/VertoChain' },
        { tag: <FaLinkedinIn />, href: '#' },
    ]

    const [isCheck, setIsCheck] = useState(false)
    const [values, setValues] = useState({
        name: '',
        nameError: '',
        email: '',
        emailError: '',
        password: '',
        showPassword: false,
        passwordError: '',
        repeatPassword: '',
        showRepeatPassword: false,
        repeatPasswordError: '',
        nationality: 'EUR',
        nationalityError: '',
        countryCode: '+1',
        countryFalg: engFlag,
        phoneNumber: '',
        phoneNumberError: '',
        currency: '',
        currencyError: '',
        amount: '',
        amountError: '',
    });

    const [isToggle, setIsToggle] = useState(false);
    const year = new Date().getFullYear()

    useEffect(() => { })

    const functions = {
        _handleChange: (prop) => (event) => {
            setValues({ ...values, emailError: '', passwordError: '', [prop]: event.target.value });
        },
        _handleClickShowPassword: (key) => {
            if (key == 'new') {
                setValues({
                    ...values,
                    showPassword: !values.showPassword,
                });
            } else {
                setValues({
                    ...values,
                    showRepeatPassword: !values.showRepeatPassword,
                });
            }
        },
        _handleMouseDownPassword: (event) => {
            event.preventDefault();
        },
        _toNavigate: () => {
            if (!values.name) {
                setValues({ ...values, nameError: 'Enter your name.', });
            }
            else if (!values.email) {
                setValues({ ...values, emailError: 'Enter your email address.', });
            }
            else if (!values.password) {
                setValues({ ...values, passwordError: 'Enter your password.', });
            } else if (!values.repeatPassword) {
                setValues({ ...values, repeatPasswordError: 'Enter password again.', });
            } else if (values.repeatPassword != values.newPassword) {
                setValues({ ...values, error: 'Both password not match.', });
            } else if (!values.nationality) {
                setValues({ ...values, nationalityError: 'Please select your nationality.', });
            }
            else if (!values.phoneNumber) {
                setValues({ ...values, phoneNumberError: 'Add phone number with country code.', });
            } else if (!values.currency) {
                setValues({ ...values, currencyError: "Please select currency.", });
            } else if (!values.amount) {
                setValues({ ...values, amountError: "Please enter amount.", });
            }
            else {
                navigate("/dashboard", { replace: true });
            }
        },
        _onToggle: () => {
            setIsToggle(!isToggle)
        },
        _onSelectCode: (item) => {
            setValues({ ...values, countryCode: item.code, countryFalg: item.flag });
            setIsToggle(false)
        }
    }

    return (
        <div className='Login Register'>
            <div className='contentContainer'>
                <div className='screenWrapper'>
                    <div className='bgImage1' />
                    <div className='bgImage2' />
                    <div className='authWrapper'>
                        <img src={logo} className="screenLogo" />
                        <div className='loginContainer col-lg-6'>
                            {/* <div className='RegisterSize'> */}

                            <div className='form loginFormWrapper'>
                                <div>
                                    <ThemeProvider theme={muiTheme}>
                                        <Typography className='reveal active fade-right' variant="h3">{t('Register')}</Typography>
                                    </ThemeProvider>

                                    <Box
                                        component="form" sx={{ '& .MuiTextField-root': { mt: 1, mb: 1, width: '100%', maxWidth: '100%', }, }}
                                        validate autoComplete="off" className="loginForm" onSubmit={functions._toNavigate}
                                    >
                                        <div>
                                            <TextField
                                                id="standard-required" label={t("Your Name")} variant='standard'
                                                value={t(values.name)} onChange={functions._handleChange('name')}
                                                error={values.nameError ? true : false} helperText={values.nameError ? t(values.nameError) : ''}
                                                className='inputStyles reveal active fade-right'
                                            />
                                            <TextField
                                                id="standard-required" label={t("E-mail")} variant='standard'
                                                value={t(values.email)} onChange={functions._handleChange('email')}
                                                error={values.emailError ? true : false} helperText={values.emailError ? t(values.emailError) : ''}
                                                className='inputStyles reveal active fade-right'
                                            />

                                            <div className='numberWrapper inputStyles  reveal active fade-right'>
                                                <div className={isToggle ? "custom-select-wrapper open-dropdown" : "custom-select-wrapper"} onClick={functions._onToggle}>
                                                    <div className='languageWrapper'>
                                                        <img src={values.countryFalg} className='flag' />
                                                        <span className='language'>{values.countryCode}</span>
                                                    </div>
                                                    <ul>
                                                        {languageData.map((item, index) => {
                                                            return (
                                                                <li className='languageWrapper' key={String(index)} onClick={() => functions._onSelectCode(item)}>
                                                                    <img src={item.flag} className='flag' />
                                                                    <span className='language'>{item.code}</span>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                                <TextField
                                                    id="standard-select-phoneNumber" placeholder={t('Phone Number')} variant='standard'
                                                    value={values.phoneNumber} onChange={functions._handleChange('phoneNumber')}
                                                    error={values.phoneNumberError ? true : false} helperText={values.phoneNumberError ? t(values.phoneNumberError) : ''}
                                                // className='inputStyles'
                                                />
                                            </div>

                                            <TextField
                                                id="standard-select-country" select label={t("Nationality")} variant='standard'
                                                value={t(values.nationality)} onChange={functions._handleChange('nationality')}
                                                error={values.nationalityError ? true : false} helperText={values.nationalityError ? t(values.nationalityError) : ''}
                                                className='inputStyles reveal active fade-right'
                                            >
                                                {nationalities.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {t(option.value)}
                                                    </MenuItem>
                                                ))}
                                            </TextField>

                                            <FormControl variant="standard" sx={{ mt: 1, mb: 1, width: '100%', maxWidth: '100%' }} className='inputStyles  reveal active fade-right'>
                                                <InputLabel htmlFor="standard-adornment-password" className={values.passwordError ? 'Mui-error' : ''}>{t("Password")}</InputLabel>
                                                <Input
                                                    id="standard-adornment-password" type={values.showPassword ? 'text' : 'password'}
                                                    value={t(values.password)} onChange={functions._handleChange('password')}
                                                    error={values.passwordError ? true : false} label={t("Password")}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility" edge="end"
                                                                onClick={() => functions._handleClickShowPassword('new')} onMouseDown={() => functions._handleMouseDownPassword('new')}
                                                            >
                                                                {values.showPassword ? <RiEyeLine color={'#828282'} size={22} /> : <RiEyeCloseLine color={'#828282'} size={22} />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }

                                                />
                                                <FormHelperText id="filled-weight-helper-text">{values.passwordError ? t(values.passwordError) : ''}</FormHelperText>
                                            </FormControl>

                                            <FormControl variant="standard" sx={{ mt: 1, mb: 1, width: '100%', maxWidth: '100%' }} className='inputStyles  reveal active fade-right'>
                                                <InputLabel htmlFor="standard-adornment-password" className={values.repeatPasswordError ? 'Mui-error' : ''}>{t("Repeat Password")}</InputLabel>
                                                <Input
                                                    id="standard-adornment-password" type={values.showRepeatPassword ? 'text' : 'password'}
                                                    value={t(values.repeatPassword)} onChange={functions._handleChange('repeatPassword')}
                                                    error={values.repeatPasswordError ? true : false} label={t("Repeat Password")}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility" edge="end"
                                                                onClick={() => functions._handleClickShowPassword('repeat')} onMouseDown={() => functions._handleMouseDownPassword('repeat')}
                                                            >
                                                                {values.showRepeatPassword ? <RiEyeLine color={'#828282'} size={22} /> : <RiEyeCloseLine color={'#828282'} size={22} />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }

                                                />
                                                <FormHelperText id="filled-weight-helper-text">{values.repeatPasswordError ? t(values.repeatPasswordError) : ''}</FormHelperText>
                                            </FormControl>

                                            <ThemeProvider theme={muiTheme}>
                                                <Typography className='purchaseText reveal active fade-right' style={{ paddingTop: 20 }} variant="h3">{t('Purches details')}</Typography>
                                            </ThemeProvider>

                                            <div className='inputStyles reveal active fade-right currencyWrapper'>
                                                <TextField
                                                    id="standard-select-currency" select label={t("Cryptocurrency")} variant='standard'
                                                    value={t(values.currency)} onChange={functions._handleChange('currency')}
                                                    error={values.currencyError ? true : false} helperText={values.currencyError ? t(values.currencyError) : ''}
                                                >
                                                    {currencies.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {t(option.value)}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>

                                                <FormControl sx={{ mt: 2, mb: 1, width: '100%', maxWidth: '100%' }} variant="standard" className='amount' >
                                                    <Input
                                                        id="standard-adornment-amount" value={t(values.amount)} onChange={functions._handleChange('amount')}
                                                        startAdornment={<InputAdornment position="end" style={{ paddingRight: 10, color: colors.black, fontSize: 18, fontWeight: 500 }}>$</InputAdornment>}
                                                        aria-describedby="standard-weight-helper-text" inputProps={{ 'aria-label': 'weight', }}
                                                        error={values.amountError ? true : false}
                                                    />
                                                    <FormHelperText id="standard-weight-helper-text">{values.amountError ? t(values.amountError) : ''}</FormHelperText>
                                                </FormControl>
                                            </div>

                                            <div className='formInnerContent'>
                                                <div className='formInnerContent rememberMeWrapper reveal active fade-bottom'>
                                                    {!isCheck ? <MdOutlineCheckBoxOutlineBlank color={'#000000'} size={24} onClick={() => setIsCheck(!isCheck)} /> : <MdOutlineCheckBox color={'#000000'} size={24} onClick={() => setIsCheck(!isCheck)} />}
                                                    <span style={{ paddingLeft: 10 }}>{t("I agree to the Terms and Conditions and Privacy Policy")}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </Box>

                                    <AppButton className={'reveal active fade-right'} title={'Create an account'} onClick={functions._toNavigate} />

                                    <div className='formInnerContent dontHaveAccWrapper reveal active fade-right'>
                                        <span style={{ paddingRight: 5 }}>{t("Already have an account?")}</span>
                                        <Link to={'/login'} className='forgotText registerNowText'>{t("Log In")}</Link>
                                    </div>

                                    <div className="socialIcon socialIconWrapper flex drawer">
                                        {socialLinks.map((item, i) => {
                                            return (
                                                <Nav.Link key={String(i)} className='icon' href={item.href}>
                                                    {t(item.tag)}
                                                </Nav.Link>
                                            )
                                        })}
                                    </div>

                                    <div className='bottomContentWrapper reveal active fade-right'>
                                        <span>{t("Privacy policy")}</span>
                                        <span>{t("Terms and Conditions")}</span>
                                    </div>
                                    <div className='bottomContentWrapper reveal active fade-right'>

                                        <span>{year}{t(" Verto All Rights Reserved")}</span>
                                    </div>
                                </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(Register)