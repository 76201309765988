import React, { useEffect, useState } from 'react'
import { AppScreensHeader, AppScreensHeader2, AppTitleHeader } from '../../components/AppHeader';
import { MDBDataTable } from 'mdbreact';
import settings from '../../assets/images/settings.png';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import { Container, Row } from 'react-bootstrap';
import { useTranslation, withTranslation } from 'react-i18next';
import searchIcon from '../../assets/images/search_icon.png'

const Transactions = props => {
    const { t, i18n } = useTranslation();

    // const bodyData = useState(Array(10).fill("").map((_, i) => ({name: 'Tiger Nixon', position: 'System Architect', office: 'Edinburgh', age: '61', date: '2011/04/25', salary: '$320'})))

    const data = {
        columns: [
            {
                label: t('Tranx NO'), field: 'first', sort: 'asc',
            },
            {
                label: t('Tokens'), field: 'second', sort: 'asc',
            },
            {
                label: t('Amount'), field: 'third', sort: 'asc',
            },
            {
                label: t('USD Amount'), field: 'forth', sort: 'asc',
            },
            {
                label: t('To'), field: 'fifth', sort: 'asc',
            },
            {
                label: t('Type'), field: 'sixth', sort: 'asc',
            }
        ],
        rows: Array(200).fill("").map((_, i) => (
            {
                first: '1235678900', second: t('Bitcoin'), third: t('Lorem Ipsum'),
                forth: '-$12.450', fifth: t('Lorem Ipsum'), sixth: [<div className={i % 2 == 0 ? 'status pending' : i % 3 == 0 ? 'status canceled' : 'status purchase'}>{i % 2 == 0 ? 'Pending' : i % 3 == 0 ? t('Canceled') : t('Purchase')}</div>],
            }
        ))
    };

    return (
        <div className='InnerScreens Transactions'>
            <div className='bImage1' />
            <div className='bImage2' />
            <AppScreensHeader2 />

            <div className='contentContainer'>

                <div className='flex settingIconDiv'>
                    <AppTitleHeader title={t('Transactions')} />
                    <div className='settingIcon'>
                        <img src={settings} />
                    </div>
                </div>

                <div className='screenWrapper'>
                    <Container>
                        <Row>
                            <div className='emptyWrapper'>
                                <div className='whiteBgWrapper'>

                                    <div className='flex wrapper'>
                                        <div className=''>
                                            <img src={searchIcon} />
                                        </div>
                                    </div>

                                    <div className='tableWrapper'>

                                        <MDBDataTable
                                            scrollX
                                            striped
                                            data={data}
                                            small
                                            maxHeight="80vh"
                                            // paginationLabel={[<BsArrowLeft />, <BsArrowRight />]}
                                            searching
                                            searchLabel={t("Seacrh by name")}
                                            pagesAmount={3}
                                            entriesLabel=""
                                            entriesOptions={[]}
                                            info={false}
                                        // scrollY
                                        // bordered
                                        // responsive
                                        // responsiveMd
                                        // responsiveLg
                                        // responsiveXl
                                        // responsiveSm
                                        />

                                    </div>

                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>

            <AppScreensHeader />


        </div>
    )
}

export default withTranslation()(Transactions)