import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, Row, Offcanvas, Button } from 'react-bootstrap';
// import logo from '../imgs/logo.png';
import { colors, muiTheme } from './../config/theme';
import '../assets/css/App.css';
import { ThemeProvider, Typography } from '@mui/material';
import { AppParagraph } from './AppText';
import { NavLink } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import { useTranslation, initReactI18next, Trans } from "react-i18next";
import cookie from 'js-cookie';
import logo from '../assets/images/logo.png';
import userImage from '../assets/images/demoImage.png';
import dIcon from '../assets/images/d_icon.png';
import bIcon from '../assets/images/bt_icon.png';
import mIcon from '../assets/images/mt_icon.png';
import tIcon from '../assets/images/tr_icon.png';
import pIcon from '../assets/images/p_icon.png';
import { RiBitCoinFill } from 'react-icons/ri';
import engFlag from '../assets/images/eng_lang.jpeg';
import frFlag from '../assets/images/fr_lang.jpeg';
import spFlag from '../assets/images/sp_lang.jpeg';
import tkFlag from '../assets/images/tk_lang.jpeg';

export const AppHeader = ({ }) => {

    const languages = [
        { name: 'English', code: 'en', country_code: 'gb', flag: engFlag },
        { name: 'Français', code: 'fr', country_code: 'fr', flag: frFlag },
        { name: 'Español', code: 'sp', country_code: 'es', flag: spFlag },
        { name: 'Türk', code: 'tr', country_code: 'tr', flag: tkFlag },
    ];

    const headerList = [
        { id: 1, title: 'Home', href: '/' },
        { id: 2, title: 'About Us', href: '/about-us' },
        { id: 3, title: 'The Tokenomics', href: '/tokenomics' },
        { id: 4, title: 'Wave Map', href: '/wave-map' }
    ]

    const currentLanguageCode = cookie.get('il8next') || 'en'
    const cuurentLanguage = languages.find(l => l.code == currentLanguageCode)
    const { t, i18n } = useTranslation();
    const [count, setCounter] = useState(0);

    const year = new Date().getFullYear()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [scroll, setScroll] = useState(false);
    const [isToggle, setIsToggle] = useState(false);
    const [selectedLang, setSelectedLang] = useState()
    const [selectedFlag, setSelectedFlag] = useState()


    useEffect(() => {
        document.body.dir = cuurentLanguage.dir || 'ltr'
        document.title = t('Verto')
        languages.filter(l => l.code == i18n.resolvedLanguage && (setSelectedLang(l.name), setSelectedFlag(l.flag)))
    }, [cuurentLanguage, t])


    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });

    }, []);

    const onToggleLang = () => {
        setIsToggle(!isToggle)
    }
    const onSelectLang = (item) => {
        // setSelectedFlag(item.flag)
        // setSelectedLang(item.name)
        setIsToggle(false)
        i18n.changeLanguage(item.code)
    }


    const styles = {
        activeNavLink: {
            color: colors.black, fontFamily: `'Poppins', sans-serif`, fontWeight: '700'
        },
    }

    return (
        <Container className='AppHeader'>
            <Row>
                <div className={scroll ? "stickyHeader" : "relativeHeader"}>
                    <Navbar expand="lg" className='navbarContainer'>
                        <Container>

                            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleShow} />

                            <Navbar.Collapse id="basic-navbar-nav">
                                <Navbar className="ms-auto">

                                    {headerList.map((item, index) => {
                                        return (
                                            <NavLink
                                                key={String(index)} className='navLink' to={item.href}
                                                style={({ isActive }) => isActive ? styles.activeNavLink : undefined}
                                            >
                                                {t(item.title)}

                                                {item.id == 3 && <div className='underline' />}
                                            </NavLink>
                                        )
                                    })}
                                </Navbar>
                            </Navbar.Collapse>

                            <Offcanvas show={show} onHide={handleClose}>
                                <Offcanvas.Body>
                                    <div>
                                        {headerList.map((item, index) => {
                                            return (
                                                <NavLink
                                                    key={String(index)} className='navLink' to={item.href}
                                                    style={({ isActive }) => isActive ? styles.activeNavLink : undefined}
                                                >
                                                    {t(item.title)}
                                                </NavLink>
                                            )
                                        })}

                                        <div className={isToggle ? "custom-select-wrapper open-dropdown" : "custom-select-wrapper"} onClick={onToggleLang}>
                                            <div className='languageWrapper'>
                                                <img src={selectedFlag} className='flag' />
                                                <span className='language'>{selectedLang}</span>
                                            </div>
                                            <ul>
                                                {
                                                    languages.map((item, index) => {
                                                        return (
                                                            <li className='languageWrapper' key={item} style={{ fontWeight: i18n.resolvedLanguage === item.code ? 'bold' : 'normal' }} onClick={() => onSelectLang(item)}>
                                                                <span className='language'>{item.name}</span>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>

                                        </div>
                                    </div>


                                    <div className="socialIconWrapper flex drawer">
                                        <Nav.Link className='icon'>
                                            <FaFacebookF />
                                        </Nav.Link>
                                        <Nav.Link className='icon' href='https://twitter.com/SupertideFi'>
                                            <FaTwitter />
                                        </Nav.Link>
                                        <Nav.Link className='icon'>
                                            <FaLinkedinIn />
                                        </Nav.Link>
                                    </div>
                                </Offcanvas.Body>
                                <div>
                                    <Container>
                                        <Row>
                                            <div className='footerWrapper'>
                                                <p className="footerText" >{t('copy_right')} &copy; {year} </p>
                                                <Nav.Link className="footerText" >Vertochain</Nav.Link>
                                            </div>
                                        </Row>
                                    </Container>
                                </div>
                            </Offcanvas>

                        </Container>
                    </Navbar>
                </div>
            </Row>
        </Container >
    )
}

export const AppHeader2 = ({ title = '', subText = '', colorText, colorTitle, textStyles }) => {
    const { t, i18n } = useTranslation();
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(true)
    }, [])

    return (
        <Container className='AppHeader2'>
            <Row>

                <div className='AppHeader2Wrapper'>
                    <div className='contentWrapper'>
                        <Container className={isActive ? 'reveal active fade-bottom' : 'reveal fade-bottom'}>
                            <ThemeProvider theme={muiTheme}>
                                {title ? <Typography style={{ color: colorTitle }} variant="h5">{t(title)}</Typography> : null}
                                {subText ? <AppParagraph text={t(subText)} color={colorText} textStyles={textStyles} /> : null}
                            </ThemeProvider>
                        </Container>
                    </div>
                </div>
            </Row>
        </Container>
    )

}

export const AppScreensHeader = () => {
    const { t, i18n } = useTranslation();

    const sideBarItems = [
        { title: 'Dashboard', href: '/dashboard' },
        { title: 'Transactions', href: '/transactions' },
        { title: 'My Token', href: '/my-token' },
        { title: 'Buy Token', href: '/buy-token' },
        { title: 'Profile', href: '/profile' },
    ]


    return (
        <div className='sideBar'>
            <div>
                <div className='sideBarFirstWrapper'>

                    <img className='logo' src={logo} />
                    <div className='userImgBox'>

                        <img className='userImg' src={userImage} />
                        <div>
                            <span>{t("Welcome,")}</span>
                            <span>{t("Kylie")}</span>
                        </div>

                    </div>

                    <ul className="unstyled list-hover-slide">
                        {sideBarItems.map((item, index) => {
                            return (
                                <NavLink
                                    key={String(index)} className='navLink' to={item.href}
                                >

                                    <span>{t(item.title)}</span>
                                </NavLink>
                            )
                        })}
                    </ul>
                </div>

            </div>
        </div>

    )
}

export const AppScreensHeader2 = ({ }) => {

    const languages = [
        { name: 'English', code: 'en', country_code: 'gb', flag: engFlag },
        { name: 'Français', code: 'fr', country_code: 'fr', flag: frFlag },
        { name: 'Español', code: 'sp', country_code: 'es', flag: spFlag },
        { name: 'Türk', code: 'tr', country_code: 'tr', flag: tkFlag },
    ];

    const sideBarItems = [
        { title: 'Dashboard', href: '/dashboard', icon: dIcon },
        { title: 'Transactions', href: '/transactions', icon: tIcon },
        { title: 'Profile', href: '/profile', icon: pIcon },
        { title: 'Buy Token', href: '/buy-token', icon: bIcon },
        { title: 'My Token', href: '/my-token', icon: mIcon },
    ]

    const { t, i18n } = useTranslation();
    const year = new Date().getFullYear()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(!show);
    const [scroll, setScroll] = useState(false);
    const [isToggle, setIsToggle] = useState(false);
    const [selectedLang, setSelectedLang] = useState('English')
    const [selectedFlag, setSelectedFlag] = useState(engFlag)
    const currentLanguageCode = cookie.get('il8next') || 'en'
    const cuurentLanguage = languages.find(l => l.code == currentLanguageCode)

    useEffect(() => {
        document.body.dir = cuurentLanguage.dir || 'ltr'
        document.title = t('Verto')
        languages.filter(l => l.code == i18n.resolvedLanguage && (setSelectedLang(l.name), setSelectedFlag(l.flag)))
    }, [cuurentLanguage, t])



    const onToggleLang = () => {
        setIsToggle(!isToggle)
    }

    const onSelectLang = (item) => {
        setIsToggle(!isToggle)
        i18n.changeLanguage(item.code)
    }

    return (
        <Container className='AppHeader AppScreensHeader2'>
            <Row>
                <div className={"relativeHeader"}>
                    <Navbar expand="lg" className='navbarContainer'>
                        <Container className='mainContainer'>
                            <Navbar.Brand href="/">
                                <img
                                    src={logo} width="100%" height="30"
                                    className="d-inline-block align-top" alt="verto logo"
                                />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleShow} />
                            <div id="drawer" className={show ? "custom-select-wrapper open-dropdown" : "custom-select-wrapper"} >
                                <ul>
                                    {
                                        sideBarItems.map((item, index) => {
                                            return (
                                                <NavLink
                                                    key={String(index)} className='navLink' to={item.href}
                                                >
                                                    <div className='icon'> <img src={item.icon} /></div>

                                                    <span>{t(item.title)}</span>
                                                </NavLink>
                                            )
                                        })
                                    }

                                </ul>
                                <div className='languageWrapper' onClick={onToggleLang}>
                                    <img src={selectedFlag} className='flag' />
                                    <span className='language'>{selectedLang}</span>
                                </div>
                                <div id="language" className={isToggle ? "custom-select-wrapper open-dropdown" : "custom-select-wrapper"} >

                                    <ul>
                                        {
                                            languages.map((item, index) => {
                                                return (
                                                    <li className='languageWrapper' key={item} style={{ fontWeight: i18n.resolvedLanguage === item.code ? 'bold' : 'normal' }} onClick={() => onSelectLang(item)}>
                                                        <img src={item.flag}/>
                                                        <span className='language'>{item.name}</span>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>




                            </div>


                        </Container>
                    </Navbar>
                </div>
            </Row>
        </Container >
    )
}

export const AppTitleHeader = ({ title = '' }) => {
    const { t, i18n } = useTranslation();

    return (
        <Container>
            <Row>
                <div className='header'>
                    <h5>{t(title)}</h5>
                </div>
            </Row>
        </Container>
    )
}
