import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { ThemeProvider, Typography } from '@mui/material';
import { muiTheme } from '../../config/theme';
import { AppParagraph } from '../../components/AppText';
import { colors } from './../../config/theme';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { RiEyeLine, RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';

import { AppButton } from '../../components/AppButton';
import { height } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { useTranslation, withTranslation } from 'react-i18next';

const CreateNewPassword = props => {

    const { t, i18n } = useTranslation();
    const [isCreatedNewPass, setIsCreatedNewPass] = useState(false)
    const [values, setValues] = useState({
        error: '',
        newPassword: '',
        showNewPassword: false,
        newPasswordError: '',
        repeatPassword: '',
        showRepeatPassword: false,
        repeatPasswordError: '',
    });
    let navigate = useNavigate();

    useEffect(() => { })

    const functions = {
        _handleChange: (prop) => (event) => {
            setValues({ ...values, newPasswordError: '', repeatPasswordError: '', error: '', [prop]: event.target.value });
        },
        _handleClickShowPassword: (key) => {
            if (key == 'new') {
                setValues({
                    ...values,
                    showNewPassword: !values.showNewPassword,
                });
            } else {
                setValues({
                    ...values,
                    showRepeatPassword: !values.showRepeatPassword,
                });
            }
        },
        _handleMouseDownPassword: (event) => {
            event.preventDefault();
        },
        _toNavigate: () => {
            navigate("/login", { replace: true });
        },
        _handleSubmit: (event) => {
            event.preventDefault();
            if (!values.newPassword) {
                setValues({
                    ...values,
                    newPasswordError: 'Enter your password.',
                });
            }
            else if (!values.repeatPassword) {
                setValues({
                    ...values,
                    repeatPasswordError: 'Enter password again.',
                });
            } else if (values.repeatPassword != values.newPassword) {
                setValues({
                    ...values,
                    error: 'Both password not match.',
                });
            } else {
                setIsCreatedNewPass(!isCreatedNewPass)
            }
        }
    }


    return (
        <div className='Login CreateNewPassword'>
            <div className='contentContainer'>
                <div className='screenWrapper'>
                    <div className='bgImage1' />
                    <div className='bgImage2' />
                    <div className='authWrapper'>
                        <img src={logo} className="screenLogo" />
                        <div className='loginContainer col-lg-6'>
                            {/* <div className={isCreatedNewPass ? 'CreateNewPassSize DonePageSize' : 'CreateNewPassSize'}> */}

                            <div className='form loginFormWrapper'>
                                <ThemeProvider theme={muiTheme}>
                                    {!isCreatedNewPass && <Typography className='reveal active fade-right' variant="h3">{t('Enter new password')}</Typography>}
                                    {!isCreatedNewPass && <Typography className='reveal active fade-right' variant="h6">{t('Create a new password of at least 6 characters')}</Typography>}
                                    {isCreatedNewPass && <Typography className='reveal active fade-right' variant="h3">{t('We almost finised')}</Typography>}
                                    {isCreatedNewPass && <AppParagraph text={t('We have sent you the necessary instructions to recover your password in your E-mail.')} color={'#828282'} className={'authParaText reveal active fade-right'} />}
                                </ThemeProvider>

                                {!isCreatedNewPass && <Box
                                    component="form" sx={{ '& .MuiTextField-root': { mt: 1, mb: 1, width: '100%', maxWidth: '100%', }, }}
                                    validate autoComplete="off" className="loginForm" onSubmit={functions._handleSubmit}
                                >
                                    <div>
                                        <FormControl variant="standard" sx={{ mt: 1, mb: 1, width: '100%', maxWidth: '100%' }} className='inputStyles  reveal active fade-right'>
                                            <InputLabel htmlFor="standard-adornment-password" className={values.newPasswordError ? 'Mui-error' : ''}>{t("Password")}</InputLabel>
                                            <Input
                                                id="standard-adornment-password" type={values.showNewPassword ? 'text' : 'password'}
                                                value={t(values.newPassword)} onChange={functions._handleChange('newPassword')}
                                                error={values.newPasswordError ? true : false} label={t("Password")}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility" edge="end"
                                                            onClick={() => functions._handleClickShowPassword('new')} onMouseDown={() => functions._handleMouseDownPassword('new')}
                                                        >
                                                            {values.showNewPassword ? <RiEyeLine color={'#828282'} size={22} /> : <RiEyeCloseLine color={'#828282'} size={22} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }

                                            />
                                            <FormHelperText id="filled-weight-helper-text">{values.newPasswordError ? t(values.newPasswordError) : ''}</FormHelperText>
                                        </FormControl>

                                        <FormControl variant="standard" sx={{ mt: 1, mb: 1, width: '100%', maxWidth: '100%' }} className='inputStyles  reveal active fade-right'>
                                            <InputLabel htmlFor="standard-adornment-password" className={values.repeatPasswordError ? 'Mui-error' : ''}>{t("Repeat Password")}</InputLabel>
                                            <Input
                                                id="standard-adornment-password" type={values.showRepeatPassword ? 'text' : 'password'}
                                                value={t(values.repeatPassword)} onChange={functions._handleChange('repeatPassword')}
                                                error={values.repeatPasswordError ? true : false} label={t("Password")}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility" edge="end"
                                                            onClick={() => functions._handleClickShowPassword('repeat')} onMouseDown={() => functions._handleMouseDownPassword('repeat')}
                                                        >
                                                            {values.showRepeatPassword ? <RiEyeLine color={'#828282'} size={22} /> : <RiEyeCloseLine color={'#828282'} size={22} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }

                                            />
                                            <FormHelperText id="filled-weight-helper-text">{values.repeatPasswordError ? t(values.repeatPasswordError) : ''}</FormHelperText>
                                        </FormControl>
                                        <FormHelperText id="filled-weight-helper-text" className="errorMsg">{values.error ? t(values.error) : ''}</FormHelperText>
                                    </div>
                                </Box>}



                                {!isCreatedNewPass && <AppButton className={'reveal active fade-right'} title={t('Save')} onClick={functions._handleSubmit} />}
                                {isCreatedNewPass && <AppButton className={'widthAuto reveal active fade-right'} title={t('Go to Log In page')} onClick={functions._toNavigate} />}
                            </div>

                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(CreateNewPassword)