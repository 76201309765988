import React from 'react'
import {
    Route,
    Routes
} from "react-router-dom";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import AccountRecovery from '../views/auth/AccountRecovery';
import CreateNewPassword from '../views/auth/CreateNewPassword';
import Login from '../views/auth/Login';
import Register from '../views/auth/Register';
import Home from '../views/home';
import BuyToken from '../views/innerScreens/BuyToken';
import Dashboard from '../views/innerScreens/Dashboard';
import MyToken from '../views/innerScreens/MyToken';
import Profile from '../views/innerScreens/Profile';
import Transactions from '../views/innerScreens/Transactions';

const AppRoutes = props => {
    return (
        <main className="Router" >
            {/* <Router>
                <Switch>
                    <Route path="/" > <Home /> </Route>
                    <Route path="/login" > <Login /> </Route>
                    <Route path="/account-recovery" > <AccountRecovery /> </Route>
                    <Route path="/create-new-password" > <CreateNewPassword /> </Route>
                </Switch>
            </Router> */}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/account-recovery" element={<AccountRecovery />} />
                <Route path="/create-new-password" element={<CreateNewPassword />} />
                <Route path="/register" element={<Register />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/buy-token" element={<BuyToken />} />
                <Route path="/my-token" element={<MyToken />} />
                <Route path="/transactions" element={<Transactions />} />
                <Route path="/profile" element={<Profile />} />
            </Routes>
        </main>
    )
}

export default AppRoutes
