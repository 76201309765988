import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { ThemeProvider, Typography } from '@mui/material';
import { muiTheme } from '../../config/theme';
import { AppParagraph } from '../../components/AppText';
import { colors } from './../../config/theme';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { RiEyeLine, RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { AppButton } from '../../components/AppButton';
import checkbox from '../../assets/images/checkBoc.png'
import { Link, useNavigate } from 'react-router-dom';
import Input from '@mui/material/Input';
import logo from '../../assets/images/logo.png';
import { useTranslation, withTranslation } from 'react-i18next';

const Login = props => {
    const { t, i18n } = useTranslation();
    let navigate = useNavigate();
    const [isCheck, setIsCheck] = useState(false)

    const [values, setValues] = useState({
        email: '',
        password: '',
        showPassword: false,
        emailError: '',
        passwordError: ''
    });

    useEffect(() => { })

    const functions = {
        _handleChange: (prop) => (event) => {
            setValues({ ...values, emailError: '', passwordError: '', [prop]: event.target.value });
        },
        _handleClickShowPassword: () => {
            setValues({
                ...values,
                showPassword: !values.showPassword,
            });
        },
        _handleMouseDownPassword: (event) => {
            event.preventDefault();
        },
        _toNavigate: () => {
            if (!values.email) {
                setValues({
                    ...values,
                    emailError: 'Enter email address',
                });
            }
            else if (values.password == "") {
                setValues({
                    ...values,
                    passwordError: 'Enter password',
                });
            }
            else {
                navigate("/dashboard", { replace: true });
            }
        }
    }



    return (
        <div className='Login'>
            <div className='contentContainer'>
                <div className='screenWrapper'>
                    <div className='bgImage1' />

                    <div className='bgImage2' />
                    <div className='authWrapper'>
                        <img src={logo} className="screenLogo" />
                        <div className='loginContainer col-lg-6'>

                            <div className='form loginFormWrapper'>
                                <ThemeProvider theme={muiTheme}>
                                    <Typography className='reveal active fade-right' variant="h3">{t('Log in')}</Typography>

                                    {/* <AppParagraph text={'Log in'} className={'authParaText reveal active fade-right'} /> */}
                                </ThemeProvider>

                                <Box
                                    component="form" sx={{ '& .MuiTextField-root': { mt: 1, mb: 1, width: '100%', maxWidth: '100%', }, }}
                                    validate autoComplete="off" className="loginForm" onSubmit={functions._toNavigate}
                                >
                                    <div>
                                        <TextField
                                            id="standard-basic" label={t("E-mail")} variant="standard"
                                            value={values.email} onChange={functions._handleChange('email')}
                                            error={values.emailError ? true : false} helperText={values.emailError ? t(values.emailError) : ''}
                                            className='inputStyles reveal active fade-right'
                                        />
                                        {/* <FormControl variant='outlined' sx={{ mt: 1, mb: 1, width: '100%', maxWidth: '100%' }} className='inputStyles  reveal active fade-right'>
                                            <InputLabel htmlFor="outlined-adornment-password" className={values.passwordError ? 'Mui-error' : ''}>Password</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password" type={values.showPassword ? 'text' : 'password'}
                                                value={values.password} onChange={functions._handleChange('password')}
                                                error={values.passwordError ? true : false} label="Password"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility" edge="end"
                                                            onClick={functions._handleClickShowPassword} onMouseDown={functions._handleMouseDownPassword}
                                                        >
                                                            {values.showPassword ? <RiEyeFill color={colors.black} size={20} /> : <RiEyeOffFill color={colors.black} size={20} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }

                                            />
                                            <FormHelperText id="filled-weight-helper-text">{values.passwordError ? "Enter your password." : ''}</FormHelperText>
                                        </FormControl> */}
                                        <FormControl sx={{ mt: 1, mb: 1, width: '100%', maxWidth: '100%' }} className='inputStyles reveal active fade-right' variant="standard">
                                            <InputLabel htmlFor="standard-adornment-password">{t("Password")}</InputLabel>
                                            <Input
                                                id="standard-adornment-password"
                                                type={values.showPassword ? 'text' : 'password'}
                                                value={t(values.password)}
                                                onChange={functions._handleChange('password')}
                                                error={values.passwordError ? true : false}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility" edge="end"
                                                            onClick={functions._handleClickShowPassword} onMouseDown={functions._handleMouseDownPassword}
                                                        >
                                                            {values.showPassword ? <RiEyeLine color={'#828282'} size={22} /> : <RiEyeCloseLine color={'#828282'} size={22} />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            <FormHelperText id="filled-weight-helper-text">{values.passwordError ? t(values.passwordError) : ''}</FormHelperText>
                                        </FormControl>

                                        <div className='formInnerContent'>
                                            <div className='formInnerContent rememberMeWrapper reveal active fade-bottom'>
                                                {/* <img src={checkbox} /> */}
                                                {!isCheck ? <MdOutlineCheckBoxOutlineBlank color={colors.black} size={24} onClick={() => setIsCheck(!isCheck)} /> : <MdOutlineCheckBox color={colors.black} size={24} onClick={() => setIsCheck(!isCheck)} />}
                                                <span style={{ paddingLeft: 5 }}>{t("Remember me")}</span>
                                            </div>
                                            <Link to={'/account-recovery'} className='forgotText reveal active fade-bottom'>{t("Forgot your password?")}</Link>
                                        </div>
                                    </div>
                                </Box>
                                <div className='loginButton'>
                                    <AppButton className={'reveal active fade-right'} title={t('Log In')} onClick={functions._toNavigate} />
                                </div>
                                <div className='formInnerContent dontHaveAccWrapper reveal active fade-bottom'>
                                    <span style={{ paddingRight: 15 }}>{t('Don’t have an account?')}</span>
                                    <Link to={'/register'} className='forgotText registerNowText'>{("Register Now")}</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(Login)