import React, { useEffect, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2';
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
} from 'chart.js';
import { useTranslation } from 'react-i18next';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);

export const AppChart = props => {
    const { t, i18n } = useTranslation();

    const data = {
        labels: Array(20).fill("").map((_, i) => ("21 Mar'22 4:00")),
        datasets: [
            {
                label: '',
                lineTension: 0.1,
                fill: false,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: "#90EDF8",
                pointBackgroundColor: "#90EDF8",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#1368BD",
                pointHoverBorderColor: "#1368BD",
                pointHoverBorderWidth: 2,
                pointRadius: 5,
                backgroundColor: "#06CEEA",
                borderColor: "#06CEEA",
                borderWidth: 3,
                data: [10, 20, 30, 40, 100, 50, 100],
            }
        ]
    }

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        title: {
            display: true,
            text: ''
        },

        animations: {
            radius: {
                duration: 400,
                easing: 'linear',
                loop: (context) => context.active
            },
            tooltip: {
                enabled: true
            }
        },
        plugins: false,
        // plugins: {
        //     title: {
        //         display: true,
        //         text: ''
        //     },
        // },
        hoverRadius: 12,
        hoverBackgroundColor: 'yellow',
        interaction: {
            mode: 'nearest',
            intersect: false,
            axis: 'x'
        },
        legend: {
            display: false
        },
        scales: {
            y: {
                beginAtZero: true,
                // title: { display: true, text: "" },
                // ticks: {
                //     stepSize: 1,
                //     beginAtZero: true,
                // },
                ticks: {
                    // Include a dollar sign in the ticks
                    stepSize: 30,
                    callback: function (value, index, ticks) {
                        return value + " " + 'USD';
                    }
                }
            },
            x: {
                // ticks: {
                //     display: false //this will remove only the label
                // },
                grid: {
                    drawBorder: false
                },
                adapters: {
                    date: { locale: 'en' },
                    type: "time",
                    distribution: "linear",
                    time: {
                        parser: "yyyy- MM - dd",
                        unit: "month"
                    },
                    title: {
                        display: true,
                        text: "Date"
                    }
                },
                gridLines: {
                    display: false,
                    drawBorder: false,
                },
            },
            xAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Date'
                },
                ticks: {
                    display: false //this will remove only the label
                },
                gridLines: {
                    display: false,
                    drawBorder: false,
                  },
            }],
            yAxes: [{
                display: true,
                //type: 'logarithmic',
                scaleLabel: {
                    display: true,
                    labelString: 'Index Returns'
                },
                ticks: {
                    // min: 0,
                    // max: 500,
                    stepSize: 200
                }
            }],
            // legend: {
            //     labels: {
            //         fontSize: 26
            //     }
            // }
        }
    }


    return (
        <>
            <Line
                data={data}
                height={100}
                options={options}
                lang={i18n.resolvedLanguage}
                style={{ vh: 50 }}
                datasetIdKey="id"
            />
        </>
    )
}