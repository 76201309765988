import React, { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap';
import { useTranslation, withTranslation } from 'react-i18next';
import { AppButton } from '../../components/AppButton';
import { AppScreensHeader, AppScreensHeader2, AppTitleHeader } from '../../components/AppHeader';
import Box from '@mui/material/Box';
const MyToken = props => {
    const { t, i18n } = useTranslation();


    const data = [
        {
            balance: '12 768,80', amount: '14 628,90', puchased: '463,78', referal: 'Over', bonus: 'Over', total: '15 635,00', isUpdate: true,
        },
        {
            stage: 'Phase 1', amount: '14 628,90', puchased: '463,78', referal: 'Over', bonus: 'Over', total: '15 635,00'
        }
    ]

    return (
        <div className='InnerScreens MyToken'>
            <div className='bImage1' />

            <div className='bImage2' />
            <AppScreensHeader2 />
            <div className='contentContainer'>
                <AppTitleHeader title={t('My Token')} />

                <div className='screenWrapper'>
                    <Container>
                        <Row>
                            <div className='emptyWrapper'>
                                <div className='flex screenFlexWrapper'>
                                    {data.map((item, i) => {
                                        return (
                                            <div key={String(i)} className='whiteBgWrapper'>
                                                {item.isUpdate ?
                                                    <div className='flex wrapper wrapperWithBtn'>
                                                        <div style={{ width: '100%' }}>
                                                            <span>{t('token balance')}</span>
                                                            <h2>{t(item.balance)}</h2>
                                                            <AppButton className={'TokenButton AppScreensButtonSecondary'} title={t('Buy more Token')} />
                                                        </div>
                                                    </div> :
                                                    <div className='wrapper'>
                                                        <span>{t('stage name')}</span>
                                                        <h2>{t(item.stage)}</h2>
                                                    </div>
                                                }

                                                <div className='divider' />
                                                <div className='wrapper'>
                                                    <span>{t('total token amount')}</span>
                                                    <h3>{t(item.amount)}</h3>
                                                </div>

                                                <div className='divider' />
                                                <div className='flex wrapper group'>

                                                    <div>
                                                        <span>{t('Purchased Token')}</span>
                                                        <h5>{t(item.puchased)}</h5>
                                                    </div>
                                                    <div>
                                                        <span>{t('Referal Token')}</span>
                                                        <h5>{t(item.referal)}</h5>
                                                    </div>
                                                    <div>
                                                        <span>{t('Bonuses Token')}</span>
                                                        <h5>{t(item.bonus)}</h5>
                                                    </div>
                                                </div>

                                                <div className='divider' />
                                                <div className='wrapper'>
                                                    <span>{t('total contributed')}</span>
                                                    <h3>{t(item.total)}</h3>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>
            <AppScreensHeader />

        </div>
    )
}

export default withTranslation()(MyToken)