import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { ThemeProvider, Typography } from '@mui/material';
import { muiTheme } from '../../config/theme';
import { AppParagraph } from '../../components/AppText';
import { colors } from './../../config/theme';
import { AppButton } from '../../components/AppButton';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { withTranslation, useTranslation } from 'react-i18next';

const AccountRecovery = props => {
    const { t, i18n } = useTranslation();
    const [values, setValues] = useState({
        email: '',
        emailError: '',
    });
    const [isAccRecovery, setIsAccRecovery] = useState(false)
    let navigate = useNavigate();
    useEffect(() => {
    })

    const functions = {
        _handleChange: (prop) => (event) => {
            setValues({ ...values, emailError: '', [prop]: event.target.value });
        },
        _handleSubmit: (event) => {
            event.preventDefault();
            if (!values.email) {
                console.log('if');
                setValues({
                    ...values,
                    emailError: 'Enter email address',
                });
            }
            else {
                setIsAccRecovery(!isAccRecovery)
            }
        },
        _toNavigate: () => {
            navigate("/create-new-password", { replace: true });
        },
    }

    return (
        <div className='Login AccountRecovery'>
            <div className='contentContainer'>
                <div className='screenWrapper'>
                    <div className='bgImage1' />
                    <div className='bgImage2' />
                    <div className='authWrapper'>
                        <img src={logo} className="screenLogo" />
                        <div className='loginContainer col-lg-6'>
                            {/* <div className='AccountRecoverySize'> */}

                                <div className='form loginFormWrapper'>
                                    <ThemeProvider theme={muiTheme}>
                                        <Typography variant='h3' className='reveal active fade-right'>{t('Account recovery')}</Typography>
                                        {isAccRecovery && <AppParagraph text={t('Password recovery information sent to  your email address')} color={'#828282'} className={'authParaText reveal active fade-right'} />}
                                    </ThemeProvider>

                                    {!isAccRecovery && <Box
                                        component="form" sx={{ '& .MuiTextField-root': { mt: 1, mb: 1, width: '100%', maxWidth: '100%', }, }}
                                        validate autoComplete="off" className="loginForm" onSubmit={functions._handleSubmit}
                                    >
                                        <div>

                                            <TextField
                                                id="standard-basic" label={t("E-mail")} variant="standard"
                                                value={t(values.email)} onChange={functions._handleChange('email')}
                                                error={values.emailError ? true : false} helperText={values.emailError ? t(values.emailError) : ''}
                                                className='inputStyles reveal active fade-right'
                                            />
                                        </div>
                                    </Box>}

                                    {!isAccRecovery && <AppButton className={'reveal active fade-right'} title={t('Send')} onClick={functions._handleSubmit} />}
                                    {isAccRecovery && <AppButton className={'reveal active fade-right widthAuto'} title={t('Create new password')} onClick={functions._toNavigate} />}

                                    {!isAccRecovery && <div className='formInnerContent dontHaveAccWrapper reveal active fade-right'>
                                        <Link to={'/login'} className='forgotText registerNowText'>{t("Back to Log in")}</Link>
                                        {/* <a className='forgotText registerNowText'>Back to Log in</a> */}
                                    </div>}
                                </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(AccountRecovery)