import React, { useEffect, useState } from 'react'
import { AppScreensHeader, AppScreensHeader2, AppTitleHeader } from '../../components/AppHeader';
import { AppChart } from '../../components/AppChart';
import { Container, Row } from 'react-bootstrap';
import { useTranslation, withTranslation } from 'react-i18next';

const Dashboard = props => {

    const { t, i18n } = useTranslation();

    return (
        <div className='InnerScreens Dashboard'>
            <div className='bImage1' />
            <div className='bImage2' />
            <AppScreensHeader2 />
            <div className='contentContainer'>
                
                <AppTitleHeader title={t('Token Price Graph')} />
                <div className='screenWrapper'>
                    <Container>
                        <Row>
                            <div className='emptyWrapper'>
                                <div className='whiteBgWrapper chart'>
                                    <div style={{ height: '80vh' }}>
                                        <AppChart />
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>

            <AppScreensHeader />


        </div>
    )
}

export default withTranslation()(Dashboard)




