import React from 'react'
import { ThemeProvider, Typography } from "@mui/material";
import { muiTheme } from '../config/theme';
import { Container } from 'react-bootstrap';

export const AppParagraph = ({ text, color, textStyles, variant, className }) => {
    return (
        <ThemeProvider theme={muiTheme}>
            <Typography className={className} style={textStyles || { color: color }} variant={variant || "body1"}>{text || ''}</Typography>
        </ThemeProvider>
    )
}

export const AppTextImageGrid = ({ variant, title, subText, subText2, bg, source, titleStyles, subTextStyles, colorText, directionReverse, classPath }) => {
    return (
        <div className='AppTextImageGrid' style={{ backgroundColor: bg, }}>
            <Container className={classPath ? classPath : directionReverse ? 'reveal fade-right' : 'reveal fade-left'}>
                <div className='textImgGridContainer' style={{ flexDirection: directionReverse ? 'row-reverse' : 'row' }}>
                    <div className='textGridWrapper'>
                        <ThemeProvider theme={muiTheme}>
                            <Typography style={titleStyles} variant={"h4"}>{title}</Typography>
                            <AppParagraph variant={variant} text={subText} color={colorText} textStyles={subTextStyles} />
                            <AppParagraph variant={variant} text={subText2} color={colorText} textStyles={subTextStyles} />
                        </ThemeProvider>
                    </div>
                    <div className='imgGridWrapper'>
                        <img
                            src={source} className="d-inline-block align-top image" alt=" Supertidefi logo"
                        />
                        <div className="shadow"></div>
                    </div>
                </div>
            </Container>
        </div>
    )
}