import React, { useEffect, useState } from 'react'
import { AppScreensHeader, AppScreensHeader2, AppTitleHeader } from '../../components/AppHeader';
import { Box, FormHelperText, InputAdornment, MenuItem, OutlinedInput, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { colors } from './../../config/theme';
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { IoIosRadioButtonOff, IoIosRadioButtonOn } from 'react-icons/io';
import { AppButton } from '../../components/AppButton';
import { Container, Row } from 'react-bootstrap';
import { useTranslation, withTranslation } from 'react-i18next';
import { borderRadius } from '@mui/system';
import zIndex from '@mui/material/styles/zIndex';

const BuyToken = props => {

    const { t, i18n } = useTranslation();

    const currencies = [
        { value: 'Ethereum (ETH)', },
        { value: 'Ethereum (ETH)', },
        { value: 'Ethereum (ETH)', },
        { value: 'Ethereum (ETH)', },
    ];
    const [payCheck, setPayCheck] = useState(false)
    const [agreeCheck, setAgreeCheck] = useState(false)

    const [values, setValues] = useState({
        currency: '',
        currencyError: '',
        amount: '',
        amountError: '',
    });

    const functions = {
        _handleChange: (prop) => (event) => {
            setValues({ ...values, currencyError: '', amountError: '', [prop]: event.target.value });
        },
        _toNavigate: () => {
            if (!values.currency) {
                setValues({ ...values, currencyError: "Please select currency.", });
            } else if (!values.amount) {
                setValues({ ...values, amountError: "Please enter amount.", });
            }
            // else { }
        },
    }

    return (
        <div className='InnerScreens BuyToken'>
            <div className='bImage1' />

            <div className='bImage2' />

            <AppScreensHeader2 />


            <div className='contentContainer'>

                <div className='whiteBgContainer'>
                    <AppTitleHeader title={t('Buy Token')} />
                    <div className='screenWrapper'>
                        <Container>
                            <Row>
                                <div className='emptyWrapper'>
                                    <div className='whiteBgWrapper'>
                                        <p className='title'>{t('Pleace select payment method')}</p>

                                        <Box
                                            component="form"
                                            sx={{ '& .MuiTextField-root': { width: '100%', maxWidth: '100%', }, }}
                                            noValidate autoComplete="off" className="buyTokenForm"
                                        >
                                            <div className='inputWrapper col-lg-8'>
                                                <TextField
                                                    id="standard-select-currency" variant='standard' select label={t("Pleace select payment method")}
                                                    value={t(values.currency)}
                                                    onChange={functions._handleChange('currency')}
                                                    error={values.currencyError ? true : false}
                                                    helperText={values.currencyError ? t(values.currencyError) : ''}
                                                >
                                                    {currencies.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {t(option.value)}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </div>

                                            <div className='inputWrapper flex amountWrapper col-lg-8'>

                                                <TextField
                                                    label={t("Enter the amount in dollars")}
                                                    id="standard-start-adornment"
                                                    variant='standard'
                                                    sx={{}}
                                                    InputProps={{ endAdornment: <InputAdornment position="end" >{t("USD")}</InputAdornment>, }}
                                                    value={t(values.amount)}
                                                    onChange={functions._handleChange('amount')}

                                                />

                                                <div className='flex amount'>
                                                    <span>=</span>
                                                    <div>
                                                        <p>{t('0.000000')}</p>
                                                        <p>{t('ETH')}</p>
                                                    </div>
                                                </div>
                                                <div className='flex amount'>
                                                    <span>=</span>
                                                    <div>
                                                        <p>{t('0.000000')}</p>
                                                        <p>{t('SPT')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='inputWrapper fieldset'>
                                                {!payCheck ? <IoIosRadioButtonOff color={'#B4F0F9'} size={40} onClick={() => setPayCheck(!payCheck)} /> : <IoIosRadioButtonOn color={'#B4F0F9'} size={40} onClick={() => setPayCheck(!payCheck)} />}
                                                <span>{t('Pay with crypto')}</span>
                                            </div>


                                            <div className='formInnerContent'>
                                                <span>{t('*Payment gateway may change you a processing fears.')}</span>
                                            </div>

                                            <div className='formInnerContent formInnerContent2'>
                                                {!agreeCheck ? <MdOutlineCheckBoxOutlineBlank color={colors.erieBlack} size={30} onClick={() => setAgreeCheck(!agreeCheck)} /> : <MdOutlineCheckBox color={colors.erieBlack} size={30} onClick={() => setAgreeCheck(!agreeCheck)} />}
                                                <span style={{ paddingLeft: 5, fontWeight: 500, fontSize: '16px' }}>{t('I hereby agree to the token purchase agreement and token sale term.')}</span>
                                            </div>

                                            <AppButton className={'TokenButton AppScreensButtonSecondary widthAuto'} title={t('Make Payment')} />

                                        </Box>
                                    </div>
                                </div>
                            </Row>
                        </Container>
                    </div>
                </div >
            </div >
            <AppScreensHeader />

        </div >
    )
}

export default withTranslation()(BuyToken)